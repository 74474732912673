import { jsx as _jsx } from "react/jsx-runtime";
import { FaPills } from 'react-icons/fa';
import { CgPill } from 'react-icons/cg';
import { MdLocalDrink, MdGrain } from 'react-icons/md';
import { GiPowder, GiSpoon } from 'react-icons/gi';
import Color from 'color';
const GalenicIcon = ({ name, color, size }) => {
    const colorContrast = Color(color).isDark() ? '#FFFFFF' : '#333333';
    switch (name.trim()) {
        case 'comprimé': {
            return _jsx(FaPills, { size: size, color: colorContrast });
        }
        case 'gélule': {
            return _jsx(CgPill, { size: size, color: colorContrast });
        }
        case 'solution buvable': {
            return _jsx(MdLocalDrink, { size: size, color: colorContrast });
        }
        case 'solution buccale': {
            return _jsx(MdLocalDrink, { size: size, color: colorContrast });
        }
        case 'poudre pour solution buvable': {
            return _jsx(GiPowder, { size: size, color: colorContrast });
        }
        case 'sirop': {
            return _jsx(GiSpoon, { size: size, color: colorContrast });
        }
        case 'granulés': {
            return _jsx(MdGrain, { size: size, color: colorContrast });
        }
        default: {
            return _jsx(FaPills, { size: size, color: colorContrast });
        }
    }
};
export default GalenicIcon;
